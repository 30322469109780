import React from "react"


import SEO from "../components/seo"
import About from "../components/about"
import SummaryBlock from "../components/summary-block"

import Layout from "../layouts/en"

import content from "../content"
import Footer from "../components/footer"
import LandingBlock from "../components/landing-block"

const IndexPage = (props) => {
  const indexProps={name:"Maria Budarevskaya", ...props}
  return (
  <Layout location={props.location}>

    <SEO title="Maria Budareskaya" />

      <div id="about" className="w-screen grid cols-1 lg:grid-cols-2 bg-gray-750">
        <About {...indexProps} />
      
        <SummaryBlock/>
        
        
      </div>  

    <div className="bg-gray-100 shadow-inner">
    
    {/* <Stories data={content.getStories('en') } {...props}/> */}
    <div id="nextpage" className="divide-y-4 divide-gray-200 shadow">
    {content.getStories('en').map((story, index) => {
              return <LandingBlock key={index} data={story} />
            })}
    </div>
    </div>
    <Footer />
    </Layout> 
)}

export default IndexPage
